import Vue from "vue";
import Router from "vue-router";
import { ROLES } from "@/common/config/acl/roles";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      meta: {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER,
          ROLES.ADVERTISER_ADMIN,
          ROLES.ADVERTISER_USER
        ]
      },
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Audience.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER,
              ROLES.PUBLISHER_ADMIN,
              ROLES.PUBLISHER_USER,
              ROLES.ADVERTISER_ADMIN,
              ROLES.ADVERTISER_USER
            ]
          }
        },
        {
          path: "/followers",
          name: "followers",
          component: () => import("@/views/pages/Follower.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER,
              ROLES.PUBLISHER_ADMIN,
              ROLES.PUBLISHER_USER,
              ROLES.ADVERTISER_ADMIN,
              ROLES.ADVERTISER_USER
            ]
          }
        },
        {
          path: "/podcasts",
          name: "podcasts",
          component: () => import("@/views/pages/Podcasts.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER,
              ROLES.PUBLISHER_ADMIN,
              ROLES.PUBLISHER_USER
            ]
          }
        },
        {
          path: "/settings/domains",
          name: "settings_domains",
          component: () => import("@/views/pages/settings/domains.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER,
              ROLES.ADVERTISER_ADMIN,
              ROLES.PUBLISHER_ADMIN
            ]
          }
        },
        {
          path: "/settings/domains/create",
          name: "settings_domains_create",
          component: () => import("@/views/pages/settings/domains.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER
            ]
          }
        },
        {
          path: "/settings/domains/:id",
          name: "settings_domain_edit",
          component: () => import("@/views/pages/settings/domains.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER,
              ROLES.ADVERTISER_ADMIN,
              ROLES.PUBLISHER_ADMIN
            ]
          }
        },
        {
          path: "/settings/content/:id",
          name: "settings_content_item",
          component: () => import("@/views/pages/settings/content.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER,
              ROLES.PUBLISHER_ADMIN,
              ROLES.PUBLISHER_USER
            ]
          }
        },
        {
          path: "/settings/content/create",
          name: "settings_content_item_create",
          component: () => import("@/views/pages/settings/content.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER,
              ROLES.PUBLISHER_ADMIN,
              ROLES.PUBLISHER_USER
            ]
          }
        },
        {
          path: "/revenue",
          name: "revenue",
          component: () => import("@/views/pages/Revenue.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.PUBLISHER_ADMIN,
              ROLES.PUBLISHER_USER
            ]
          }
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/views/pages/admin/user/Profile.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER,
              ROLES.PUBLISHER_ADMIN,
              ROLES.PUBLISHER_USER,
              ROLES.ADVERTISER_ADMIN,
              ROLES.ADVERTISER_USER
            ]
          }
        },
        {
          path: "/admin/companies",
          name: "admin_list_companies",
          component: () => import("@/views/pages/admin/company/List.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER
            ]
          }
        },
        {
          path: "/company",
          name: "create_company",
          component: () => import("@/views/pages/company/Edit.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER
            ]
          }
        },
        {
          path: "/company/:id",
          name: "edit_company",
          component: () => import("@/views/pages/company/Edit.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER,
              ROLES.PUBLISHER_ADMIN,
              ROLES.PUBLISHER_USER,
              ROLES.ADVERTISER_ADMIN,
              ROLES.ADVERTISER_USER
            ]
          }
        },
        {
          path: "/admin/podcasts",
          name: "admin_list_podcasts",
          component: () => import("@/views/pages/admin/podcast/List.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN]
          }
        },
        {
          path: "/admin/podcasts/:id/episodes",
          name: "admin_list_podcasts_episodes",
          component: () => import("@/views/pages/admin/episode/List.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN]
          }
        },
        {
          path: "/admin/podcast*",
          name: "admin_edit_podcast",
          component: () => import("@/views/pages/admin/podcast/Edit.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN]
          }
        },
        {
          path: "/adstudio/delivery/orders",
          name: "adstudio_delivery_orders",
          component: () => import("@/views/pages/sponsoring/ListV2.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_ADVERTISER]
          }
        },
        {
          path: "/adstudio/delivery/order/create",
          name: "adstudio_delivery_order_create",
          component: () => import("@/views/pages/sponsoring/EditV2.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_ADVERTISER]
          }
        },
        {
          path: "/adstudio/delivery/order/:id",
          name: "adstudio_delivery_order_edit",
          component: () => import("@/views/pages/sponsoring/EditV2.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_ADVERTISER]
          }
        },
        {
          path: "/adstudio/delivery/order/:id/details",
          name: "adstudio_delivery_order_details",
          component: () => import("@/views/pages/sponsoring/DetailsV2.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_ADVERTISER]
          }
        },
        {
          path: "/adstudio/monitoring",
          name: "adstudio_monitoring",
          component: () => import("@/views/pages/sponsoring/Monitoring.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_ADVERTISER]
          }
        },
        {
          path: "/dashboard/conversion",
          name: "conversion",
          component: () => import("@/views/pages/Conversion.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
          }
        },
        {
          path: "/admin/categories",
          name: "categories",
          component: () => import("@/views/pages/admin/category/List.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
          }
        },
        {
          path: "/faq",
          name: "faq",
          component: () => import("@/views/pages/help/Faq.vue"),
          meta: {
            roles: [
              ROLES.BBB_ADMIN,
              ROLES.BBB_MANAGER_PUBLISHER,
              ROLES.BBB_MANAGER_ADVERTISER,
              ROLES.PUBLISHER_ADMIN,
              ROLES.PUBLISHER_USER,
              ROLES.ADVERTISER_ADMIN,
              ROLES.ADVERTISER_USER
            ]
          }
        },
        {
          path: "/discovery/simulator",
          name: "recommendation_url",
          component: () => import("@/views/pages/discovery/Simulator.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
          }
        },
        {
          path: "/discovery/monitoring/pages",
          name: "discovery_monitoring_pages",
          component: () => import("@/views/pages/discovery/PageMonitoring.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
          }
        },
        {
          path: "/invoices",
          name: "invoices_generator",
          component: () => import("@/views/pages/Invoice.vue"),
          meta: {
            roles: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
          }
        }
      ]
    },
    {
      // the 404 route, when none of the above matches
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          path: "/claim/validation/:accountKey/:feedHash/:validationKey",
          name: "claimValidation",
          component: () => import("@/views/pages/Claim"),
          meta: {
            roles: ["guest"]
          }
        }
      ]
    },
    {
      // the 404 route, when none of the above matches
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          path:
            "/claim/premium/validation/:claimToken/:feedHash/:validationKey",
          name: "premiumClaimValidation",
          component: () => import("@/views/pages/PremiumClaim"),
          meta: {
            roles: ["guest"]
          }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login"),
          meta: {
            roles: ["guest"]
          }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "forgot_password",
          path: "/forgot-password",
          component: () => import("@/views/pages/auth/ForgotPassword"),
          meta: {
            roles: ["guest"]
          }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "reset_password",
          path: "/forgot-password/reset-password",
          component: () => import("@/views/pages/auth/PasswordReset"),
          meta: {
            roles: ["guest"]
          }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "welcome",
          path: "/welcome",
          component: () => import("@/views/pages/auth/PasswordWelcome"),
          meta: {
            roles: ["guest"]
          }
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue"),
      meta: {
        roles: [
          ROLES.BBB_ADMIN,
          ROLES.BBB_MANAGER_PUBLISHER,
          ROLES.BBB_MANAGER_ADVERTISER,
          ROLES.PUBLISHER_ADMIN,
          ROLES.PUBLISHER_USER,
          ROLES.ADVERTISER_ADMIN,
          ROLES.ADVERTISER_USER,
          "guest",
          "customer",
          "customer_paid"
        ]
      }
    }
  ]
});
