// France
export const locale = {
  TRANSLATOR: {
    SELECT: "choisissez votre langue"
  },
  MENU: {
    NEW: "Nouveau",
    ACTIONS: "Actes",
    CREATE_POST: "Créer un nouveau Post",
    PAGES: "Pages",
    FEATURES: "Fonctionnalités",
    APPS: "Applications",
    DASHBOARD: "Tableau de Bord",
    PODCASTS: "Podcasts",
    STATS: "Statistiques",
    AUDIENCE: "Audience",
    PROFILE: "Manage account",
    SPONSORING: "Sponsoring",
    FOLLOWERS: "Followers",
    MANAGECAMPAIGNS: "Manage Campaigns",
    DELIVERY: "Livraison",
    BROADCAST: "Diffusion",
    MANAGEREVENUS: "Manage Revenus",
    ADSTUDIO: "AdStudio",
    DISCOVERY: {
      SECTION: "Discovery",
      SIMULATOR: "Simulateur",
      MONITORING: {
        PAGES: "Pages Monitoring"
      }
    },
    ANALYTICS: "Analytics",
    NEWCAMPAIGN: "New campaign",
    REVENUS: "Revenus",
    ACCOUNT: "Account",
    INVOICES: "Invoices",
    ADVANCED_SETTINGS: {
      HOSTING: "Hosting",
      MY_COMPANY: "My Company",
      DOMAINS: "Domains",
      PLAYLIST: "Playlists"
    },
    HELP: {
      SECTION: "Aide",
      FAQ: "FAQ"
    },
    CONFIGURATION: {
      MAIN: "Configuration",
      USERS: "Utilisateurs",
      COMPANIES: "Entreprises",
      PODCASTS: "Podcasts",
      PREMIUM: "Premium",
      WEBSITES: "Sites",
      PARAMETERS: "Paramètres",
      CATEGORIES: "Catégories",
      GENERATOR: {
        EDITOR: "Generateur de titre"
      }
    },
    TRENDS: "Tendance"
  },
  TYPES: {
    company: {
      LABEL: "Company | Companies"
    }
  },
  ERRORS: {
    EXPIRED_PASSWORD_DAYS:
      "Please note that your password is about to expire in {days} day(s). Please click here to renew it !",
    EXPIRED_PASSWORD:
      "Votre mot de passe est sur le point d'expirer. Veuillez cliquer ici pour le renouveler !",
    REVENUE_DATA_START:
      "Earnings unavailable for selected dates: please select dates starting from August 2022"
  },
  AUTH: {
    GENERAL: {
      OR: "Ou",
      SUBMIT_BUTTON: "Soumettre",
      NO_ACCOUNT: "Ne pas avoir de compte?",
      SIGNUP_BUTTON: "Registre",
      FORGOT_BUTTON: "Mot de passe oublié",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Créer un compte",
      BUTTON: "Sign In",
      FORGOT: "Forgot Password?",
      ERRORS: {
        INVALID_CREDENTIALS: "Identifiant incorrect !",
        LOGIN_EXPIRED_PASSWORD:
          "Votre mot de passe vient d'expirer. Veuillez cliquer ici pour le renouveler !"
      }
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
      BUTTON: "Reset Password"
    },
    RESET: {
      TITLE: "Reset Your Voxeus Password",
      MESSAGE: "Please enter your new password to log in to the platform.",
      BUTTON: "Save Password"
    },
    WELCOME: {
      TITLE: "Welcome",
      MESSAGE:
        "Welcome to the Voxeus platform, please enter a password to log in.",
      BUTTON: "Register"
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Mot de passe",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Nom d'utilisateur"
    },
    VALIDATION: {
      INVALID: "{{name}} n'est pas valide",
      REQUIRED: "{{name}} est requis",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Nombre d'enregistrements sélectionnés: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "éCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Les clients",
      CUSTOMERS_LIST: "Liste des clients",
      NEW_CUSTOMER: "Nouveau client",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Suppression du client",
        DESCRIPTION: "Êtes-vous sûr de supprimer définitivement ce client?",
        WAIT_DESCRIPTION: "Le client est en train de supprimer ...",
        MESSAGE: "Le client a été supprimé"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Supprimer les clients",
        DESCRIPTION:
          "Êtes-vous sûr de supprimer définitivement les clients sélectionnés?",
        WAIT_DESCRIPTION: "Les clients suppriment ...",
        MESSAGE: "Les clients sélectionnés ont été supprimés"
      },
      UPDATE_STATUS: {
        TITLE: "Le statut a été mis à jour pour les clients sélectionnés",
        MESSAGE:
          "Le statut des clients sélectionnés a été mis à jour avec succès"
      },
      EDIT: {
        UPDATE_MESSAGE: "Le client a été mis à jour",
        ADD_MESSAGE: "Le client a été créé"
      }
    }
  },
  DATATABLE: {
    NO_DATA: "Aucune donée à afficher",
    PROGRAMS: "programmes",
    LOADING: "Chargement"
  },
  TRENDS: {
    TAB_TRENDS: {
      TITLE_TAB: "Audiences",
      FIRST_TABLE: {
        TITLE: "Top lecture par domaine",
        FIRST_COLUMN: "Domaines",
        SECOND_COLUMN: "Écoutes",
        THIRD_COLUMN: "% lectures",
        FOURTH_COLUMN: "Pages",
        FITH_COLUMN: "% lectures par pages"
      },
      SECOND_TABLE: {
        TITLE: "Top lecture par programme",
        FIRST_COLUMN: "Program",
        SECOND_COLUMN: "Écoutes",
        THIRD_COLUMN: "Pages"
      }
    },
    TAB_REFERERS: {
      TITLE_TAB: "Sources",
      FIRST_TABLE: {
        TITLE: "Top sources par domaine",
        FIRST_COLUMN: "Sources",
        SECOND_COLUMN: "Sessions",
        THIRD_COLUMN: "Pages",
        FOURTH_COLUMN: "Écoutes"
      }
    },
    TAB_REVENUES: {
      TITLE_TAB: "Revenu",
      FIRST_TABLE: {
        TITLE: "Top revenu par domaine",
        FIRST_COLUMN: "Domaines",
        SECOND_COLUMN: "Écoutes"
      },
      SECOND_TABLE: {
        TITLE: "Top revenu par programme",
        FIRST_COLUMN: "Programmes",
        SECOND_COLUMN: "Revenues"
      }
    },
    TAB_INVENTORY: {
      TITLE_TAB: "Inventaires",
      FIRST_TABLE: {
        TITLE: "Top inventaire par domaine",
        FIRST_COLUMN: "Domaines",
        SECOND_COLUMN: "Inventaires",
        THIRD_COLUMN: "% d'inventaires"
      },
      SECOND_TABLE: {
        TITLE: "Top inventaire par programme",
        FIRST_COLUMN: "Programmes",
        SECOND_COLUMN: "Inventaires"
      }
    }
  },
  BROADCAST: {
    NAME: "Nom",
    STATUS: "Status",
    START_DATE: "Date de départ",
    END_DATE: "Date de fin",
    DIFF_DAY: "Différence de jour",
    BUDGET: "Budget",
    COST_PER_LISTEN: "Cpe (Coup par écoutesP",
    RATIO: "Ratio",
    SPENT: "Dépensé",
    PACING: "Pacing",
    LISTENS_DELIVER: "Écoutes à délivrer par jour"
  },
  DELIVERY: {
    PROGRAM: "Programme",
    DOMAIN: "Domaine",
    SPONSORED: "Lecture sponsorisée",
    INVENTORY: "Inventaire",
    FILL_RATING: "Taux de remplissage"
  },
  SPONSORING_EDIT: {
    HELP_BOOSTER:
      "Vous pouvez utiliser la fonction Booster pour prioriser certains campagne, pour accélérer la livraison.",
    BOOSTERS_LIST: {
      NORMAL:
        "Diffusion de campagne par défaut, le mode 'Normal' diffusera votre campagne à un rythme régulier sur les dates sélectionnées.",
      HIGH:
        "Le mode'High' augmentera la diffusion de votre campagne x5, par rapport aux autres campagnes actives.",
      HIGHEST:
        "Le mode de 'Highest' augmentera la diffusion de votre campagne x10, par rapport aux autres campagnes actives."
    }
  },
  NATIVE_REVENUE: {
    TOOLTIPS: {
      EARNING: "Revenus nets totaux estimés des publicités natives",
      INVENTORY:
        "Nombre de fois qu'il y a eu une opportunité de jouer un épisode sponsorisé.",
      IMPRESSIONS: "Nombre de fois qu'un épisode sponsorisé a été affiché.",
      CONVERSION_RATE:
        "Nombre de jeux sponsorisés divisé par le nombre d'impressions d'annonces natives.",
      FILL_RATE:
        "Le nombre de jeux sponsorisés divisé par l'inventaire d'annonces natives.",
      IMPRESSION_RPM:
        "Revenu moyen estimé généré pour mille impressions d'annonces natives.",
      PLAY_RPM: "Revenu moyen estimé généré pour mille ecoute organic."
    }
  },
  CLAIM_VALIDATION: {
    SIGNUP: "S'inscrire",
    ENTER_DETAILS_BELOW: "Entrez vos coordonnées ci-dessous",
    FIRSTNAME: "Prénom",
    LASTNAME: "Nom",
    EMAIL: "E-mail",
    PASSWORD: "Mot de passe",
    CONFIRM_PASSWORD: "Confirmation du mot de passe",
    READ_AND_AGREE: "J'ai lu et je suis d'accord avec",
    TERMS_AND_CONDITIONS: "les Conditions d'utilisation",
    AND: "et",
    PRIVACY_POLICY: "Politique de confidentialité",
    FREE: {
      TITLE: "Connexion"
    },
    PREMIUM: {
      TITLE: "Valider le claim du Podcast premium",
      TITLE_REGISTRED: "Validation de la demande",
      APPROVE: "J'approuve de la demande",
      DISAPPROVE: "Je désapprouve de la demande"
    },
    SUCCESS: {
      SIGN_IN: "Inscription réussie, vous pouvez vous connecter"
    },
    ERROR: {
      MIN_CHARACTERS_2: "Entrez au moins 2 caractères",
      MIN_CHARACTERS_4: "Entrez au moins 4 caractères",
      EMPTY_INPUT: "Veuillez entrer quelque chose",
      EMAIL_WRONG_FORMAT: "Email au mauvais format",
      NOT_IDENTICAL_PASSWORD: "Les mots de passe ne sont pas les identiques",
      EMPTY_CHECKBOX: "Veuillez cocher la case",
      ERROR_SERVER: "Une erreur est survenue",
      LINK_EXPIRED: "Le lien a expiré",
      ERROR_PASSWORD:
        "Le mot de passe doit comporter au moins 8 caractères et doit inclure au moins une lettre majuscule, un chiffre et un caractère spécial."
    }
  },
  CATEGORIES: {
    TITLE: "Liste des Catégories",
    DELETE_ITEM: "Êtes-vous sur de vouloir supprimer cet élément?",
    NEW_ITEM: "Nouvelle catégorie",
    SAVE: "Enregistrer",
    CANCEL: "Annuler",
    OK: "Ok",
    EXPORT: "Export",
    CREATED: "Categorie créée avec succès",
    UPDATED: "Categorie mise à jour avec succès",
    DELETED: "Categorie supprimée  avec succès",
    ERROR_CREATED: "Une erreur est survenue, impossible de créer la catégorie",
    ERROR_UPDATED: "Une erreur est survenue, impossible de créer la catégorie",
    ERROR_DELETED: "Une erreur est survenue, impossible supprimer la catégorie"
  },
  USERS: {
    DELETED: "Utilisateur supprimé avec succès",
    DELETE_ACTION:
      'Êtes-vous sûr de vouloir supprimer le compte de l\'utilisateur "{firstName} {lastName}" ?',
    DELETE_ERROR: "Une erreur est survenue",
    RESET_PWD_ACTION:
      "Êtes-vous sûr de vouloir réinitialiser le mot de passe de {name} ?",
    DIALOG: {
      DELETE_USER_TITLE: "Suppression d'un utilisateur",
      RESET_PWD: "Renouvellement de mot de passe"
    }
  },
  MODAL: {
    TITLE_SUCCESS: "Information",
    TITLE_ERROR: "Erreur",
    OK: "Ok",
    YES: "Oui",
    NO: "Non"
  },
  DASHBOARD_CONVERSION: {
    MENU: "Conversion",
    DATATABLE: {
      DOMAIN: "Domaine",
      SESSIONS: "Sessions",
      PAGEVIEWS: "Pages vues",
      PLAYS: "Ecoutes",
      SESSIONS_CONVERSION: "% Session",
      PAGEVIEWS_CONVERSION: "% Page vue"
    },
    CARDS: {
      TOOLTIPS: {
        SESSIONS: "Nombre de Sessions",
        PAGES: "Nombre de Pages vues",
        PLAYS: "Nombre de lecture",
        SESSIONSRATE:
          "Nombre de lecture organique divisée par le nombre de Session",
        PAGESRATE:
          "Nombre de lecture organique divisée par le nombre de Pages vues"
      }
    }
  },
  LOG: {
    CREATED_AT: "Date & heure",
    USER: "Utilisateur",
    FIELDS_CHANGED: "Description",
    ACTION: "Type",
    LEVEL: "Niveau",
    RESOURCE_ID: "Resource id"
  },
  ADVANCED_SETTINGS: {
    CONTENT: {
      CONFIG_PROGRAM: {
        TITLE: "General Settings",
        LOADING: "Podcast is Loading",
        LABEL_DOMAIN: "Domain",
        LABEL_NAME: "Playlist title",
        LABEL_FLUX: "Feed URL",
        LABEL_PATH: "Path URL",
        LABEL_COPYRIGHT: "Copyright link",
        LABEL_ORDER: "Order of appearance",
        TOOLTIP_ORDER:
          'The order of appearance refers to the location of your playlist among all your other podcasts. If you want your playlist to appear in the first position on your audio subdomain ("Audiohub"), then indicate "1".',
        LABEL_COPYRIGHT_EMAIL: "Copyright email",
        LABEL_DESCRIPTION: "Description",
        TOOLTIP_DESCRIPTION:
          "Décrivez en quelques lignes le contenu de votre podcast, trouvez le ton qui le définit. Utilisez des mots-clés, en lien avec votre contenu. Éveillez la curiosité de vos auditeurs avec des phrases accrocheuses et claires.",
        LABEL_THUMBNAILS: "Picture (1400 * 1400)",
        TOOLTIP_THUMBNAILS:
          "For optimal rendering, upload an image of 1400 x 1400px. The minimum expected quality for a podcast image is 400 x 400px.",
        LABEL_ITUNES: "Itunes category",
        TOOLTIP_ITUNES:
          "Cette catégorie permet à vos auditeurs de trouver des contenus en rapport avec une thématique précise et de faire en sorte que votre podcast soit bien référencé. ",
        LABEL_TYPE: "Publication order",
        LABEL_AUTHOR: "Author",
        TOOLTIP_AUTHOR: "Nom du créateur affiché publiquement aux auditeurs",
        LABEL_LANGUAGES: "Language",
        TOOLTIP_LANGUAGES:
          "Choisir la langue permet de mieux catégoriser et  recommander votre podcast en fonction de la langue de l'utilisateur."
      },
      FORM_ERROR: {
        DOMAIN: "Please select a domain",
        COMPANY: "Please select a domain",
        FLUX: "Wrong format",
        FEED_URL: "Please put an RSS feed",
        COPYRIGHT_LINK:
          "Insérez votre lien en respectant le format suivant : https:// ",
        COPYRIGHT_EMAIL: "Wrong format",
        THUMBNAILS: "Format : JPG ou PNG, poids max. : 10 Mo",
        CATEGORIY: "Please select a category",
        DESCRIPTION:
          "Veuillez saisir une description  pour présenter votre playlist",
        AUTHOR: "Wrong format",
        TYPE: "Please select one item"
      }
    }
  },
  DOMAIN: {
    TITLE: "Domains",
    LOADING: "Domain is loading",
    NO_DOMAIN_CREATE: "No domain to display",
    NO_DOMAIN: "Please select a domain or",
    CREATE_DOMAINE: "Create one",
    CONFIG_DOMAIN: {
      TITLE: "Domains",
      COMPANY: "Company",
      LABEL_ENABLED: "Enabled",
      LABEL_NAME: "Name",
      LABEL_DOMAINS: "Hostname",
      LABEL_TITLE: "Title",
      LABEL_DESCRIPTION: "Description",
      LABEL_TEMPLATE: "Template"
    },
    CONFIG_DESIGN: {
      TITLE: "Configuration",
      LABEL_LOGO: "Logo",
      LABEL_LOGO_LINK: "Logo Link",
      LABEL_FAVICON: "Favicon",
      TITLE_COLORS_FONTS: "Colors & fonts",
      LABEL_BACKGROUND_COLOR: "Background color:",
      LABEL_FONT_COLOR: "Font color:"
    },
    CONFIG_DISCOVERY: {
      TITLE: "Discovery settings"
    },
    CONFIG_LINKS: {
      TILE: "Links",
      LABEL_TERMS: "Terms link",
      LABEL_PRIVACY: "Privacy link",
      LABEL_LEGALS: "Legals link",
      LABEL_LINKS: "About link",
      WEBSITE: {
        LABEL: "Logo link",
        TOOLTIP:
          'Enter here the link located at the level of the logo on your "audiohub'
      }
    },
    CONFIG_ADS: {
      TITLE: "Advertisement (Admin)",
      LABEL_AUTH_ADS: "Authorized Ads",
      LABEL_ADS_AUDI_ADS: "Activate Audio Ad",
      LABEL_ADS_BACKFILL: "Audio ads backfill",
      LABEL_SELLERS: "Sellers",
      PUBLISHER_AUDIOADS_CPM: "Publisher AudioAds CPM",
      PUBLISHER_AUDIOADS_FILLRATE: "Publisher AudioAds Fill Rate"
    },
    CONFIG_CONF: {
      TITLE: "Conf (Admin)",
      LABEL_REDIRECT_ALONE: "Redirect alone",
      LABEL_ADVERTISER: "Is advertiser ?",
      LABEL_ORDERED: "Ordered",
      LABEL_REDIRECT_TO: "Redirecto to",
      LABEL_DEFAULT_LOCALE: "Default Locale",
      LABEL_ORGANIZATION_NAME: "Organization name"
    },
    CONFIG_GOOGLE_SEARCH: {
      TITLE: "Google search (Admin)",
      LABEL_ROBOTS: "Robots google verification"
    },
    CONFIG_ONE_SIGNAL: {
      TITLE: "One signal (Admin)",
      LABEL_ID: "One signal ID",
      LABEL_AUTH: "One signal Auth",
      LABEL_DATABASE: "One signa Database"
    },
    CONFIG_GDPR: {
      TITLE: "GDPR (Admin)",
      LABEL_CMP: "CMP"
    },
    FORM_ERROR: {
      DOMAIN: "Please select a domain",
      COMPANY: "Please select a company",
      NAME: "Enter at least 3 letters",
      DOMAIN_NAME: "Enter at least 3 letters",
      TITLE: "Enter at least 3 letters",
      DESCRIPTION: "Enter at least 3 letters",
      TEMPLATE: "Enter at least 3 letters",
      TERMS_LINK: "Insert your link in the following format: https://",
      PRIVACY_LINK: "Insert your link in the following format: https://",
      LEGAL_LINK: "Insert your link in the following format: https://",
      ABOUT_LINK: "Insert your link in the following format: https://",
      WEBSITE: "Insert your link in the following format: https://"
    },
    TOAST: {
      SUCCESS: {
        TITLE: "Domain",
        MESSAGE_CREATE: "Domain create with success",
        MESSAGE_UPDATE: "Domain update with success"
      },
      ERROR: {
        TITLE: "Domain",
        MESSAGE: "Something went wrong."
      }
    },
    SUBMIT: "Save changes"
  },
  CONTENT: {
    PROGRAMS_FOUND: "No programs found",
    NO_PROGRAM: "Please select a program or",
    EPISODES_LIST: "Episode list",
    RSS_FEED: "RSS Feed",
    DOMAIN_PROGRAM: "Listen",
    CHANGE_PROGRAM_ORDER: "Change order",
    ADD_CONTENT: "Add Content",
    IMPORT: {
      LABEL_PROGRAM: "Import podcast",
      INPUT_FEEDURL_LABEL: "Enter the podcast feed url :",
      INPUT_FEEDURL_PLACEHOLDER: "Feed URL",
      SELECT_DOMAIN_PLACEHOLDER: "Select a Domain",
      SELECT_PARTITION_PLACEHOLDER: "Select a Partition",
      BUTTON: {
        CANCEL: "Cancel",
        IMPORT: "Import"
      },
      VALIDATOR: {
        INVALID_FEEDBACK: "Please enter a valid feed url",
        ITEM_REQUIRED: "Item is required",
        MESSAGESERVICE: {
          SUCCESS_IMPORTED: "Program imported",
          SUCCESS_UPDATED: "Program updated",
          ERROR_IMPORTED:
            "Something was wrong. Please contact us : support@ba-ba-bam.com",
          ERROR_NO_FILEDS_EMPTY: "Please fill in all the fields."
        }
      }
    },
    CHOICES: {
      LABEL_PROGRAM: "Import podcast",
      TEXT_PLAYLIST: "Create your own playlist from your catalog of episodes.",
      LABEL_PLAYLIST: "Create a playlist",
      TEXT_PROGRAM:
        "Copy and paste the feed of your podcast and automatically import all of its episodes."
    },
    THUMBNAILS: {
      EPISODES: "Episodes",
      PLAYLIST: "Playlist",
      DISABLED: "Disabled"
    },
    CONFIG_PROGRAM: {
      TITLE: "General Settings",
      LOADING: "Podcast is Loading",
      LABEL_DOMAIN: "Domain",
      LABEL_PLAYLIST_NAME: "Playlist title",
      LABEL_PROGRAM_NAME: "Program name",
      LABEL_SUBTITLE: "Subtitle",
      TOOLTIP_SUBTITLE:
        "The subtitles will improve the natural referencing on search engines and streaming platforms, thus increasing the visibility of your podcasts through keywords.",
      LABEL_FLUX: "Feed URL",
      LABEL_PATH: "Path URL",
      LABEL_COPYRIGHT: "Copyright link",
      LABEL_ORDER: "Order of appearance",
      TOOLTIP_ORDER:
        'The order of appearance refers to the location of your playlist among all your other podcasts. If you want your playlist to appear in the first position on your audio subdomain ("Audiohub"), then indicate "1".',
      LABEL_COPYRIGHT_EMAIL: "Copyright email",
      LABEL_DESCRIPTION: "Description",
      LABEL_EXPLICIT: "Explicit content",
      TOOLTIP_EXPLICIT:
        "If your podcast episode contains any words, topics or references to any content that may be considered age sensitive subjects, then we usually recommend you mark your episode as explicit. It doesn't impact your podcast at all, it just gives it a parental advisory label.",
      TOOLTIP_DESCRIPTION:
        "Describe in a few lines the content of your podcast, find the tone that defines it. Use keywords related to your content. Spark your listeners' curiosity with catchy and clear sentences.",
      LABEL_THUMBNAILS: "Picture (1400 * 1400)",
      TOOLTIP_THUMBNAILS:
        "For optimal rendering, upload an image of 1400 x 1400px. The minimum expected quality for a podcast image is 400 x 400px.",
      FORMAT_THUMBNAILS: "Les formats suivant sont acceptés: png, jpeg, jpg",
      LABEL_ITUNES: "Itunes category",
      TOOLTIP_ITUNES:
        "This category allows your listeners to find content related to a specific theme and ensure that your podcast is well referenced",
      LABEL_TYPE: "Publication order",
      TOOLTIP_TYPE:
        "Episodic: New episodes appear first, and there's no listening order. Serial: Episodes must be listened to in order.",
      LABEL_AUTHOR: "Author",
      TOOLTIP_AUTHOR: "This name will be publicly displayed to the listeners",
      LABEL_LANGUAGES: "Language",
      TOOLTIP_LANGUAGES:
        "Choosing the language helps to better categorize and recommend your podcast based on the user's language."
    },
    CONFIG_ADVERTISEMENT: {
      TITLE: "Ads Settings (Admin)",
      TITE_NATIVE_ADS: "Native Ads",
      TITLE_AUDIO_ADS: "Audio Ads Tag",
      LABEL_ADS: "Activate Native Ads",
      LADEL_AUDIO_ADS: "Activate Audio Ads",
      LABEL_BACK_FILL: "Back fill only",
      LABEL_AUDIO_ADS: "Audio ads",
      LABEL_CAPPING: "Capping"
    },
    CONFIG_ADVANCED: {
      TITLE: "Advanced (Admin)",
      LABEL_HOME: "Home display",
      LABEL_FORCE: "Force",
      LABEL_REDIRECT_TO: "Redirect to",
      LABEL_PARTITION: "Partition",
      LABEL_PARENT: "Parent",
      LABEL_LANGUAGES: "Tag languages",
      LABEL_CATEGORIES: "Tag IAB"
    },
    CONFIG_EPISODE: {
      TITLE: "Episodes selection",
      WARNING: "Please select at least one episode",
      LOADING: "Loading",
      LABEL_SELECT_COL: "Select"
    },
    FORM_ERROR: {
      DOMAIN: "Please select a domain",
      COMPANY: "Please select a domain",
      FLUX: "Wrong format",
      FEED_URL: "Please put an RSS feed",
      COPYRIGHT_LINK: "Insert your link in the following format: https://",
      COPYRIGHT_EMAIL: "Wrong format",
      THUMBNAILS: "Format: JPG or PNG, maximum size: 10 MB.",
      CATEGORIY: "Please select a category",
      DESCRIPTION: "Please enter a description to present your playlist",
      AUTHOR: "Wrong format",
      TYPE: "Please select one item",
      NAME: "Invalid name"
    },
    TOAST: {
      SUCCESS: {
        TITLE: "Program",
        MESSAGE_CREATE: "Program create with success",
        MESSAGE_UPDATE: "Program update with success",
        MESSAGE_DELETE: "Program remove with success"
      },
      ERROR: {
        TITLE: "Program",
        MESSAGE: "Something went wrong."
      }
    },
    SUBMIT: "Submit",
    SAVE: "Save",
    LABEL_DELETE: "Delete this program",
    TOOLTIP_DELETE: "Please contact your manager to delete this content",
    DELETE: {
      TITLE: "Delete program",
      LABEL: "Are you sure you want to remove this program ?",
      CANCEL: "Cancel",
      SUBMIT: "Confirm"
    }
  }
};
