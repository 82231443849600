export const BASE_URL = ""; // The base path

export const BASE_PATH = "2.0/"; // The base path

export const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  DOWNLOAD: "DOWNLOAD"
};

export const ENTITY_NAME_IN_URI = {
  LOGIN: {
    path: "login_check"
  },
  EXPORT: {
    PROGRAMS: {
      path: "export/programs/tags"
    }
  },
  TAGS: {
    path: "tags"
  },
  TAG_TYPE: {
    path: "tagtypes"
  },
  CONFIGURATIONS: {
    CATEGORIES: {
      path: "tags"
    },
    CATEGORIES_TYPES: {
      path: "tagtypes"
    }
  },
  CAMPAIGNS: {
    CAMPAIGNS_ORDERS: {
      path: "campaign-orders",
      name: "tags"
    },
    CAMPAIGNS_DUPLICATE: {
      path: "campaign-orders",
      name: "duplicate"
    },
    ORDERS_LINES: {
      path: "campaign-orders",
      name: "campaign-order-lines"
    },
    NOTIFICATIONS: {
      path: "campaign-orders",
      name: "notifications"
    },
    TAGS: {
      path: "tags",
      languages: {
        name: "lang_type"
      },
      iab: {
        name: "iab_type"
      }
    },
    DOMAINS: {
      path: "domains",
      blacklist: "blacklist"
    }
  },
  PROGRAMS: {
    path: "programs",
    name: "episodes",
    blacklist: "blacklist",
    companies: "companies",
    clean: "clear",
    EPISODES: {
      path: "episodes",
      clean: "clear"
    }
  },
  STATS_SUMMARY: {
    path: "campaign-orders",
    name: "stats",
    value: "summary"
  },
  STATS_ORDERS_LINES_SUMMARY: {
    path: "campaign-orders",
    name: "campaign-order-lines",
    value: "stats/summary"
  },
  HELP: {
    FAQ: {
      path: "faq"
    },
    FAQ_UPLOAD: {
      path: "faq",
      name: "import"
    }
  },
  ADVERTISERS: {
    path: "advertisers",
    name: "companies"
  },
  AGENCIES: {
    path: "agencies",
    name: "companies"
  },
  GEOLOCALISATION: {
    path: "stats",
    name: "geolocation"
  },
  HEATMAP: {
    path: "stats",
    name: "heatmap"
  },
  GRAPH_CAMPAIGN_DETAILS: {
    path: "campaign-orders",
    name: "stats",
    value: "daily"
  },
  GRAPH_CAMPAIGN_PERFORMANCES: {
    path: "campaign-orders",
    value: "stats/performances"
  },
  CAMPAIGN_ORDERS_DOMAINS: {
    path: "campaign-orders",
    name: "stats",
    value: "domains"
  },
  COMPLETION_RATE_QUARTILE: {
    path: "campaign-orders/stats",
    name: "quartiles"
  },
  CAMPAIGNS_STATS_EXPORT: {
    path: "campaign-orders",
    name: "stats",
    value: "export"
  },
  LOGS: {
    path: "data-logs"
  },
  EPISODES_PROGRAMS: {
    path: "programs",
    name: "episodes"
  },
  EPISODES: {
    path: "episodes"
  },
  ITUNES_CATEGORIES: {
    path: "itunes",
    value: "sub-categories"
  },
  DOMAINS: {
    path: "domains"
  },
  COMPANIES: {
    path: "companies"
  },
  COUNTRIES: {
    path: "countries"
  },
  LANGUAGES: {
    path: "languages"
  },
  SECTIONS: {
    path: "sections"
  },
  DASHBOARD: {
    FOLLOWERS: {
      path: "stats/followers",
      GLOBAL: "global",
      DAILY: "daily"
    },
    AUDIENCES: {
      path: "stats/audiences",
      GLOBAL: "global",
      DAILY: "daily"
    },
    QUARTILES: {
      path: "stats/audiences/quartiles",
      GLOBAL: "global"
    },
    PUBLISHERS: {
      path: "stats/audiences/publishers",
      HEATMAP: "heatmap"
    },
    PROGRAMS: {
      path: "stats/audiences/programs",
      GLOBAL: "global"
    },
    DEVICES: {
      path: "stats/audiences/devices",
      GLOBAL: "global"
    },
    EPISODES: {
      path: "stats/audiences/episodes",
      GLOBAL: "global"
    },
    REVENUES: {
      path: "stats/revenues",
      pathv2: "stats/revenues/v2",
      GLOBAL: "global",
      DAILY: "daily",
      PROGRAMS: "programs"
    },
    CAMPAIGNS: {
      path: "stats/campaigns",
      GLOBAL: "global",
      DAILY: "daily"
    }
  },
  FILTERS: {
    path: "filters"
  },
  USERS: {
    path: "users",
    profile: "profile",
    FORGOT_PASSWORD: "forgot-password"
  },
  SESSION: {
    path: "session",
    forgot_password: "forgot-password",
    reset_password: "reset-password"
  },
  PASSWORD: {
    reset: "email/forgot_password"
  },
  EMAIL: {
    welcome: "email/welcome"
  },
  INVOICES: {
    path: "invoices",
    DOWNLOAD: {
      path: "download"
    }
  },
  BRANDS: {
    path: "brands"
  }
};
